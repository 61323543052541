import { IProvider, Provider } from "./provider";

export interface IKeystoremanUser {
   uid: string;
   name: string;
   email: string;
   profile: string;
   fromFirebase: boolean;
   imported: boolean;
   lastLogInAt: string;
   createdAt: string;
   updatedAt: string;
   providers: Array<IProvider>;
}

export class KeystoremanUser {
   public uid: string;
   public name: string;
   public email: string;
   public profile: string;
   public fromFirebase: boolean;
   public imported: boolean;
   public lastLogInAt: Date;
   public createdAt: Date;
   public updatedAt: Date;
   public providers: Array<Provider>;

   constructor(data: IKeystoremanUser) {
      this.uid = data.uid;
      this.name = data.name;
      this.email = data.email;
      this.profile = data.profile;
      this.fromFirebase = data.fromFirebase;
      this.imported = data.imported;
      this.lastLogInAt = new Date(data.lastLogInAt);
      this.createdAt = new Date(data.createdAt);
      this.updatedAt = new Date(data.updatedAt);
      this.providers = data.providers.map(
         provider => new Provider(provider)
      );
   }
}