<!-- {{ router.url }} -->
<nav aria-label="breadcrumb">
   <ol class="breadcrumb m-0">
       <!-- <li class="breadcrumb-item" *ngfor="let path of paths; index as i; first as isFirst">
           <a href="#">Home</a>
       </li> -->
       <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
       <ng-template 
           ngFor 
           let-path 
           [ngForOf]="paths" 
           let-i="index" 
           let-first="first"
           let-last="last">
           <li class="breadcrumb-item text-white" [ngClass]="{'active': last}" *ngIf="getShow(path)">
               <a *ngIf="!last" [routerLink]="[getRoute(path)]">
                   {{ getName(path) }}
               </a>
               {{ last ? getName(path) : '' }}
           </li>
       </ng-template>
   </ol>
</nav>