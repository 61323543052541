export const environment = {
   production: false,
   firebase: {
      apiKey: "AIzaSyCnr0JsKzrujEYpMynvheHRo3rx0pe5C2Y",
      authDomain: "keystoreman.firebaseapp.com",
      databaseURL: "https://keystoreman.firebaseio.com",
      projectId: "keystoreman",
      storageBucket: "keystoreman.appspot.com",
      messagingSenderId: "941236766322",
      appId: "1:941236766322:web:56fbb0311dc21c8cce24eb",
      measurementId: "G-4KYFRX68W5"
   },
   urlApiConsole: 'https://us-central1-dev-keystoreman.cloudfunctions.net/console'
   // urlApiConsole: 'http://127.0.0.1:5001/dev-keystoreman/us-central1/console'
};