<div class="container-fluid py-2">
   <div class="row">
       <div class="col-4 d-none d-md-block">
           
       </div>

       <div class="col-4 d-block d-md-none">
           <button 
               class="btn p-0 d-flex align-items-center">
               <i class="material-icons">menu</i>
           </button>
       </div>

       <div class="col-4">
           
       </div>
       <div class="col-4 text-end d-none d-md-block text-white">

           <div ngbDropdown class="d-inline-block">
               <button 
                   type="button" 
                   class="btn text-white" 
                   id="dropdownBasic1"
                   ngbDropdownToggle>
                   {{ authService.currentUser?.name }}
               </button>
               <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                   <button ngbDropdownItem
                       (click)="signOut()"
                       class="text-danger">
                       Cerrar sesión
                   </button>
               </div>
           </div>

       </div>

       <div class="col-4 d-block d-md-none">
           
       </div>
   </div>
</div>