import { Menu } from 'src/app/models/menu.model';

export const MENU: Array<Menu> = [
   {
      icon: 'dashboard',
      route: 'dashboard',
      title: 'Dashboard'
   },
   {
      icon: 'vpn_key',
      route: '',
      title: 'Keystore',
      children: [
         {
            icon: '',
            route: 'dashboard/keystore/apps',
            title: 'Apps',
         },
         {
            icon: '',
            route: 'dashboard/keystore/kind-of-keystores',
            title: 'Kind of Keystores',
         },
         {
            icon: '',
            route: 'dashboard/keystore/kind-of-fields',
            title: 'Kind of Fields',
         },
      ]
   },
   {
      icon: 'image',
      route: '',
      title: 'Media',
      children: [
         {
            icon: '',
            route: 'dashboard/media/images',
            title: 'Images',
         }
      ]
   },
   {
      icon: 'manage_accounts',
      route: '',
      title: 'Users',
      children: [
         {
            icon: '',
            route: 'dashboard/users/database',
            title: 'Database',
         },
         {
            icon: '',
            route: 'dashboard/users/firebase',
            title: 'Firebase',
         },
      ]
   },
   {
      icon: 'group',
      route: '',
      title: 'Permissions',
      children: [
         {
            icon: '',
            route: 'dashboard/permissions/profiles',
            title: 'Profiles',
         }
      ]
   },
   {
      icon: 'assignment',
      route: '',
      title: 'Log',
      children: [
         {
            icon: '',
            route: 'dashboard/log/records',
            title: 'Records',
         },
         {
            icon: '',
            route: 'dashboard/log/log-categories',
            title: 'Log categories',
         },
         {
            icon: '',
            route: 'dashboard/log/methods',
            title: 'Methods',
         },
      ]
   }
];