export interface IProvider {
   provider: string;
   createdAt: string;
   updatedAt: string;
}

export class Provider {
   public provider: string;
   public createdAt: Date;
   public updatedAt: Date;

   constructor(data: IProvider) {
      this.provider = data.provider;
      this.createdAt = new Date(data.createdAt);
      this.updatedAt = new Date(data.updatedAt);
   }
}