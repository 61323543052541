<main 
    class="container-fluid">
    <div class="row">

        <div 
            class="d-none d-md-block col-12 col-md-3 col-lg-3 col-xl-2 p-0 bg-primary text-terteary"
            [ngStyle]="{'height.px': outerHeight}">
            <app-side-bar></app-side-bar>
        </div>

        <div 
            class="col-12 col-md-9 col-lg-9 col-xl-10 p-0"
            [ngStyle]="{'max-height.px': outerHeight}"
            style="overflow-y: auto">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12 p-0 bg-grey-darken-4 text-white">
                        <app-header-dashboard></app-header-dashboard>
                    </div>

                    <div class="col-12 py-2 mb-2 bg-secondary">
                        <app-breadcrumb></app-breadcrumb>
                    </div>

                    <div class="col-12">
                        <router-outlet></router-outlet>
                    </div>

                    <div class="col-12 p-0 bg-grey-darken-4 text-white">
                        <app-footer-dashboard></app-footer-dashboard>
                    </div>

                </div>
            </div>
        </div>

    </div>
</main>