import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [],
  exports: [
    NgbModule,
    TableModule,
    InputSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ButtonModule,
    DropdownModule,
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
