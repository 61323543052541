import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';

import { isAdminGuard } from 'src/app/guards/is-admin/is-admin.guard';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in/is-logged-in.guard';
import { IsNotLoggedInGuard } from 'src/app/guards/is-not-logged-in/is-not-logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: SessionLayoutComponent,
    canActivate: [IsNotLoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/views/session/session.module').then(m => m.SessionModule)
      }
    ]
  },
  {
    path: 'dashboard',
    canActivate: [IsLoggedInGuard, isAdminGuard],
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/views/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
