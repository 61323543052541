import { Breadcrumb } from 'src/app/models/breadcrumb.model';

export const BREADCRUMBS: Array<Breadcrumb> = [
   {
      path: 'apps',
      name: 'Apps',
      show: true,
   },
   {
      path: 'dashboard',
      name: 'Dashboard',
      show: true,
   },
   {
      path: 'firebase',
      name: 'Firebase users',
      show: true,
   },
   {
      path: 'general',
      name: 'General',
      show: true,
   },
   {
      path: 'images',
      name: 'Images',
      show: true,
   },
   {
      path: 'keystore',
      name: '',
      show: false,
   },
   {
      path: 'kind-of-keystores',
      name: 'Kind of keystores',
      show: true,
   },
   {
      path: 'log',
      name: '',
      show: false,
   },
   {
      path: 'log-categories',
      name: 'Log categories',
      show: true,
   },
   {
      path: 'media',
      name: '',
      show: false,
   },
   {
      path: 'methods',
      name: 'Methods',
      show: true,
   },
   {
      path: 'permissions',
      name: '',
      show: false,
   },
   {
      path: 'profiles',
      name: 'Profiles',
      show: true,
   },
   {
      path: 'users',
      name: '',
      show: false,
   },
];