import { Component } from '@angular/core';

import { MENU } from 'src/app/data/menu.data';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {

  public menuData = MENU;

  public isCollapsed = true;

}
