import { CanActivateFn, Router } from '@angular/router';
import { 
  Auth,
  user
} from '@angular/fire/auth';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { inject } from '@angular/core';

import { KeystoremanUser } from 'src/app/models/keystoreman-user.model';
import { AuthService } from 'src/app/services/auth/auth.service';

export const isAdminGuard: CanActivateFn = (route, state) => {

  const authService = inject(AuthService);
  const loaderService = inject(LoaderService);
  const auth = inject(Auth);
  const router = inject(Router);

  loaderService.loading = true;
  loaderService.message = 'Cargando datos';

  return new Promise((resolve, reject) => {
    user(auth).subscribe(async (_user) => {
      if (_user){
        let token = await _user.getIdToken();

        let response = await authService.getDataUser(token);

        let ecmUser = new KeystoremanUser(response.data.user);

        // console.log(ecmUser);

        loaderService.loading = false;
        loaderService.message = '';

        if(ecmUser.profile == 'admin'){
          resolve(true);
        } else {
          await authService.signOut();
          router.navigate(['/signin']);
          resolve(false);
        }
      } else {

        loaderService.loading = false;
        loaderService.message = '';
        router.navigate(['/signin']);
        resolve(false);
      }
    });
  });
};
