import { Component } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
// import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss']
})
export class HeaderDashboardComponent {

  constructor(
    public authService: AuthService,
    // public slideMenuService: SlideMenuService
  ) { }

  signOut() {
    this.authService.signOut();
  }

}
