import { Injectable } from '@angular/core';
import { 
    HttpErrorResponse,
    HttpEvent, 
    HttpHandler, 
    HttpInterceptor, 
    HttpRequest 
} from "@angular/common/http";
import { catchError, Observable, retry, throwError } from "rxjs";
import { ToastService } from "../services/toast/toast.service";
import { LoaderService } from '../services/loader/loader.service';

@Injectable({
    providedIn: 'root'
  })
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        public loaderService: LoaderService,
        private toastService: ToastService,
    ){

    }

    intercept(
        req: HttpRequest<any>, 
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if(error.error instanceof ErrorEvent) {
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        errorMessage = `Error code: ${error.status}\n message: ${error.error.message}`;
                    }

                    console.log(error);
                    
                    this.toastService.show(
                        error.error.message,
                        {
                            classname: `bg-danger text-white rounded-lg shadow`
                        }
                    );

                    this.toastService.show(
                        error.error.error,
                        {
                            classname: `bg-danger text-white rounded-lg shadow`
                        }
                    );

                    this.toastService.show(
                        JSON.stringify(error.error.data),
                        {
                            classname: `bg-danger text-white rounded-lg shadow`
                        }
                    );
                    // window.alert(errorMessage);

                    this.loaderService.loading = false;
                    this.loaderService.message = '';

                    return throwError(() => new Error(errorMessage))
                })
            )
    }
}