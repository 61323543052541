import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { 
  HttpClientModule, 
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { SharedModule } from 'src/app/modules/shared/shared.module';

import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';

import { IsLoggedInGuard } from 'src/app/guards/is-logged-in/is-logged-in.guard';
import { IsNotLoggedInGuard } from 'src/app/guards/is-not-logged-in/is-not-logged-in.guard';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderDashboardComponent } from './components/header-dashboard/header-dashboard.component';
import { FooterDashboardComponent } from './components/footer-dashboard/footer-dashboard.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ToastsContainer } from './components/toast-container/toast-container';
import { HttpErrorInterceptor } from './interceptors/error.inteceptor';
import { ToastService } from './services/toast/toast.service';

@NgModule({
  declarations: [
    AppComponent,
    SessionLayoutComponent,
    DashboardLayoutComponent,
    LoaderComponent,
    HeaderDashboardComponent,
    FooterDashboardComponent,
    BreadcrumbComponent,
    MenuItemComponent,
    SideBarComponent,
    ToastsContainer
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    IsLoggedInGuard,
    IsNotLoggedInGuard,
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
